.email-registration-complete-account-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    overflow-y: auto;
}

.email-registration-complete-account-internal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.email-registration-complete-account-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.email-registration-complete-account-multiple-inputs-container {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.email-registration-complete-account-input-container {
    display: flex;
    flex-direction: column;
    width: 408px;
}

.email-registration-complete-account-input-label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.email-registration-complete-account-input-internal-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px black solid;
    align-items: end;
}

.email-registration-complete-account-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.email-registration-complete-account-input-field:focus {
    outline: none;
}

.email-registration-complete-account-confirm-button {
    width: 408px;
    height: 55px;
    border-radius: 5px;
    border: none;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

.email-registration-complete-account-skills-area {
    width: 408px;
}

.email-registration-complete-account-skills-area-heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}

.email-registration-complete-account-skills-container {
    display: flex;
    flex-wrap: wrap;
}

.email-registration-complete-account-add-new-skill-button {
    width: 408px;
    height: 55px;
    border: none;
    border-radius: 5px;
    line-height: 17px;
    color: white;
    cursor: pointer;
}

.email-registration-complete-account-bio-area {
    width: 408px;
}

.email-registration-complete-account-bio-heading {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
}

.email-registration-complete-account-text-area {
    width: 398px;
    height: max-content;
    border: none;
    border-bottom: 1px black solid;
    resize: none;
    max-width: 100%;
    overflow: auto;
    padding: 5px;
    font-family: 'Roboto', sans-serif;
}

.email-registration-complete-account-text-area:focus {
    outline: none;
}

.email-registration-complete-account-counter {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 12px;
    line-height: 26px;
}