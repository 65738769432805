.wall-page-full-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    overflow-y: auto;
}

.wall-page-internal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.wall-page-content {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.wall-page-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
}

.wall-page-copy {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.wall-page-image-container {
    width: 80%;
    height: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/public/images/flow-image.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.wall-page-button {
    width: 328px;
    height: 55px;
    cursor: pointer;
    line-height: 17px;
    border: none;
    border-radius: 5px;
    color: white;
}