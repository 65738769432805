.email-registration-page-buttons-row {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.email-registration-page-content {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow-y: auto;
}

.email-registration-page-buttons-container {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email-registration-page-external-container {
    width: 420px;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.email-registration-page-internal-container {
    width: 392px;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.email-registration-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 24px;
}

.email-registration-copy {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    width: 328px;
}

.email-registration-linked-copy {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
}

.email-registration-page-input-container {
    display: flex;
    flex-direction: column;
    width: 328px;
}

.email-registraion-page-password-hint {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: rgba(59, 130, 247, 1);
    width: 328px;
}

.email-registration-page-input-label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.email-registration-page-input-internal-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px black solid;
    align-items: end;
}

.email-registration-page-input-field {
    line-height: 5em;
    border: none;
    width: 98%;
    height: 40px;
}

.email-registration-page-input-field:focus {
    outline: none;
}

.email-registration-page-confirm-button {
    width: 328px;
    height: 55px;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    color: white;
}

.email-registration-checkbox-row {
    width: 328px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
}

.email-registration-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    border: 1px black solid;
    margin-right: 10px;
    cursor: pointer;
}

.email-registration-checkbox-internal-element {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: black;
}

.email-registration-checkbox-copy {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 290px;
}