.skill-card-container {
    height: 30px;
    border-radius: 5px;
    background-color: rgba(234, 234, 234, 1);
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    padding-right: 5px;
    padding-left: 5px;
}

.skill-card-container-clicked {
    height: 30px;
    border-radius: 5px;
    background-color: rgba(255, 232, 171, 1);
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    padding-right: 5px;
    padding-left: 5px;
}

.skill-card-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}